import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Label, SearchField } from 'react-aria-components';
import { useMenuAutoFocusSearchField } from './useMenuAutoFocusSearchField';
import { TextInput, TextInputProps } from '../../TextInput';

export type MenuSearchFieldProps = TextInputProps;

export const MenuSearchField: React.FC<MenuSearchFieldProps> = (props) => {
  const { autoFocus, className, ...rest } = props;
  const { ref } = useMenuAutoFocusSearchField({ autoFocus });

  return (
    <SearchField className={className}>
      <Label className={'sr-only'}>Search</Label>
      <TextInput
        autoComplete={'none'}
        ref={ref}
        withClose
        placeholder={'Search...'}
        size={'xs'}
        color={'default'}
        icon={MagnifyingGlassIcon}
        {...rest}
      />
    </SearchField>
  );
};
