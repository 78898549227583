import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Header, Section } from 'react-aria-components';
import { Button } from '#/packages/ui';
import { MenuButtonItem, MenuItem } from './MenuItem';
import { MenuSeparator, MenuSeparatorPlaceholder } from './MenuSeparator';
import { MenuItems, MenuKey, MenuSelectionMode } from './types';
import { classNames } from '../../utils/classNames';

export interface MenuSectionProps {
  size?: 'sm' | 'md' | 'lg';
  index: number;
  items: MenuItems;
  hidden?: boolean;
  hiddenKeys?: MenuKey[] | Set<MenuKey>;
  header?: React.ReactNode;
  collapsible?: boolean;
  expanded?: boolean;
  setExpanded: (ind: number) => void;
  selectionMode?: MenuSelectionMode;
  withCheckMark?: boolean;
  MenuSectionHeaderComponent?: React.FC<{
    group: React.ReactNode;
  }>;
  className?: string;
}

export const MenuSectionHeader = (props) => {
  const { group, headers, expanded, onExpanded } = props;

  const finalHeader = headers ? headers[group] : group;

  return finalHeader ? (
    <Header
      className={classNames(
        'group relative font-medium mb-1 mt-2 mx-2 text-xs text-gray-500 border border-transparent',
        onExpanded ? 'flex gap-2 justify-between hover:text-gray-700 cursor-pointer' : ''
      )}
      onClick={() => {
        onExpanded(!expanded);
      }}
    >
      {finalHeader}
      {onExpanded ? (
        <ChevronDownIcon
          className={classNames(
            'transition-all ease-in text-gray-400 w-4 h-4',
            expanded ? 'text-gray-600 -rotate-180' : 'group-hover:text-gray-600'
          )}
        />
      ) : null}
    </Header>
  ) : null;
};

export const MenuSection: React.FC<MenuSectionProps> = (props) => {
  const {
    index,
    header,
    items,
    hiddenKeys = [],
    selectionMode,
    withCheckMark,
    size,
    className,
    collapsible,
    expanded,
    setExpanded,
    MenuSectionHeaderComponent = MenuSectionHeader
  } = props;

  const isHidden = items.every(
    (item) =>
      item !== MenuSeparatorPlaceholder && Array.from(hiddenKeys).includes(item.id as MenuKey)
  );

  return (
    <Section className={classNames('flex flex-col', isHidden ? 'hidden' : 'mx-2 my-1', className)}>
      {header ? (
        <MenuSectionHeaderComponent
          group={header}
          expanded={expanded}
          onExpanded={
            collapsible ? () => setExpanded((prev) => (prev === index ? null : index)) : undefined
          }
        />
      ) : null}
      {items.map((item, i) =>
        item === MenuSeparatorPlaceholder ? (
          <MenuSeparator key={i} />
        ) : item.onClick || item.to ? (
          <MenuButtonItem
            size={size}
            key={item.id}
            item={item}
            hidden={
              (collapsible && !expanded) || Array.from(hiddenKeys).includes(item.id as MenuKey)
            }
          />
        ) : (
          <MenuItem
            key={item.id}
            item={item}
            size={size}
            hidden={
              (collapsible && !expanded) || Array.from(hiddenKeys).includes(item.id as MenuKey)
            }
            selectionMode={selectionMode}
            withCheckMark={withCheckMark}
          />
        )
      )}
    </Section>
  );
};
