'use client';
import { useEffect, useState, useTransition } from 'react';

export const useImmediateItemState = <T extends any | undefined>(
  initialState: T | undefined,
  onChange: (state: T) => void
): [T | undefined, (state: T) => void] => {
  const [_, startTransition] = useTransition();

  const [selected, setSelected] = useState<T | undefined>(initialState);

  const handleChange = (state: T) => {
    setSelected(state);
    startTransition(() => onChange(state));
  };

  useEffect(() => setSelected(initialState), [initialState]);

  return [selected, handleChange];
};
