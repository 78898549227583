import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import type { Selection } from 'react-aria-components';
import { Menu as BaseMenu } from 'react-aria-components';
import { MenuSection, MenuSectionProps } from './MenuSection';
import { MenuSeparator, MenuSeparatorPlaceholder } from './MenuSeparator';
import { IMenuItemsGroup, MenuItems, MenuKey, MenuSelectionMode } from './types';
import { buildMenuItemGroups } from './utils';
import { classNames } from '../../utils/classNames';

export interface MenuProps
  extends Pick<MenuSectionProps, 'withCheckMark' | 'MenuSectionHeaderComponent'> {
  size?: 'sm' | 'md' | 'lg';
  items?: MenuItems;
  groups?: IMenuItemsGroup[];
  selectionMode?: MenuSelectionMode;
  selectedKeys?: MenuKey[] | Set<MenuKey>;
  disabledKeys?: MenuKey[] | Set<MenuKey>;
  hiddenKeys?: MenuKey[] | Set<MenuKey>;
  autoFocus?: boolean;
  className?: string;
  emptyList?: React.ReactNode;
  children?: React.ReactNode;
  onAction?: (key: MenuKey | MenuKey[]) => void;
  onSelectionChange?: (keys: Selection) => void;
  sectionClassName?: string;
  'aria-label'?: string;
}

export const Menu: React.FC<MenuProps> = (props) => {
  const {
    size,
    items = [],
    groups: initialGroups,
    children,
    hiddenKeys,
    emptyList = 'No items',
    withCheckMark,
    sectionClassName,
    MenuSectionComponent = MenuSection,
    MenuSectionHeaderComponent,

    ...rest
  } = props;

  const groups: IMenuItemsGroup[] = useMemo(() => {
    const groups = initialGroups || buildMenuItemGroups(items);

    return groups.map((group) => {
      if (group === MenuSeparatorPlaceholder) {
        return group;
      }

      return {
        ...group,
        hidden:
          group.hidden ||
          group.items.every(
            (item) =>
              item !== MenuSeparatorPlaceholder &&
              Array.from(hiddenKeys || []).includes(item.id as MenuKey)
          )
      };
    });
  }, [items, hiddenKeys, initialGroups]);

  const allHidden = groups.every((group) => group === MenuSeparatorPlaceholder || group.hidden);
  const firstNonHiddenGroupIndex = groups.findIndex(
    (group) => group !== MenuSeparatorPlaceholder && !group.hidden
  );
  const lastNonHiddenGroupIndex = groups.findLastIndex(
    (group) => group !== MenuSeparatorPlaceholder && !group.hidden
  );

  const [expanded, setExpanded] = useState<number>(
    groups.findIndex((group) => group !== MenuSeparatorPlaceholder)
  );

  const ref = useRef<HTMLDivElement>();
  useLayoutEffect(() => {
    if (expanded !== null) {
      setTimeout(() => {
        const top = new Array(expanded)
          .fill('')
          .reduce(
            (acc, val, i) =>
              acc +
                (ref.current?.querySelector(`section:nth-child(${i + 1})`)?.clientHeight +
                  8 +
                  (i > 0 ? -2 : 1)) || 0,
            0
          );

        ref.current?.parentNode?.scrollTo?.({
          top,
          left: 0
        });
      }, 10);
    }
  }, [expanded, ref.current]);

  return (
    <>
      {allHidden ? (
        typeof emptyList === 'string' ? (
          <div className={'px-4 py-2'}>
            <span className={'text-gray-500 text-sm'}>{emptyList}</span>
          </div>
        ) : (
          emptyList
        )
      ) : null}
      <BaseMenu ref={ref} aria-label='menu' {...rest}>
        {children || (
          <>
            {groups.map((group, i) => {
              return group === MenuSeparatorPlaceholder ? (
                <MenuSeparator key={i} />
              ) : (
                <React.Fragment key={i}>
                  {i === 0 ||
                  Boolean(group.header) ||
                  group.hidden ||
                  group.separator === null ? null : (
                    <MenuSeparator />
                  )}
                  <MenuSectionComponent
                    size={size}
                    index={i}
                    items={group.items}
                    header={group.header}
                    collapsible={group.collapsible}
                    hiddenKeys={hiddenKeys}
                    selectionMode={rest.selectionMode}
                    withCheckMark={withCheckMark}
                    MenuSectionHeaderComponent={MenuSectionHeaderComponent}
                    expanded={expanded === i || [...(hiddenKeys || [])].length}
                    setExpanded={setExpanded}
                    className={classNames(
                      sectionClassName,
                      group.className,
                      i === firstNonHiddenGroupIndex ? 'pt-2 [&>header]:mt-0' : '',
                      i === lastNonHiddenGroupIndex ? 'pb-2' : ''
                    )}
                  />
                </React.Fragment>
              );
            })}
          </>
        )}
      </BaseMenu>
    </>
  );
};
