import React, { useDeferredValue, useMemo, useState } from 'react';
import { IMenuItem, MenuKey, matchMenuItem } from '#/packages/ui';

const defaultMatchOptions = (options: IMenuItem[], filter: string): MenuKey[] =>
  options
    .filter((option) => !matchMenuItem(option.searchKey || option.label, filter))
    .map((option) => (option.value || option.id) as MenuKey);

export const useMenuSearch = <Option extends IMenuItem>(
  options: Option[],
  opts?: {
    hiddenKeys?: MenuKey[];
    matchOptions?: (option: Option[], filter: string) => MenuKey[];
  }
) => {
  const { matchOptions = defaultMatchOptions, hiddenKeys } = opts || {};

  const [filter, setFilter] = useState<string | undefined>(undefined);

  const defFilter = useDeferredValue(filter);

  const filteredKeys = useMemo<MenuKey[]>(() => {
    return [
      ...(hiddenKeys || []),
      ...(defFilter?.trim().length ? matchOptions(options, defFilter) : [])
    ] as MenuKey[];
  }, [hiddenKeys, defFilter, options]);

  return {
    filter,
    filteredKeys,
    onFilterChange: setFilter
  };
};
