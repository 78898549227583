'use client';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';
import { Button, Input, InputProps } from 'react-aria-components';
import { mergeRefs } from 'react-merge-refs';
import { classNames } from '../../utils/classNames';

export type TextInputProps = Omit<InputProps, 'size'> & {
  className?: string;
  side?: 'left' | 'middle' | 'right';
  size?: 'small' | 'medium' | 'large';
  color?: 'default' | 'neutral';
  invalid?: boolean;
  truncate?: boolean;
  withClose?: boolean;
  autoFocusDelay?: number;
  unit?: React.ReactNode;
  icon?: React.FC<{ className?: string }>;
  inputClassName?: string;
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const {
    side,
    size = 'medium',
    color = 'default',
    width,
    withClose,
    unit,
    icon: Icon,
    invalid,
    truncate,
    disabled,
    autoFocusDelay,
    className,
    inputClassName,
    ...rest
  } = props;

  const localRef = React.useRef<HTMLInputElement>();

  useEffect(() => {
    if (autoFocusDelay) {
      const timeout = setTimeout(() => {
        localRef.current?.focus();
      }, autoFocusDelay);

      return () => clearTimeout(timeout);
    }
  }, [localRef.current, autoFocusDelay]);

  return (
    <div className={classNames('relative', width ? `w-[${width}]` : '', className)}>
      <Input
        ref={mergeRefs([ref, localRef])}
        disabled={disabled}
        {...rest}
        className={classNames(
          'w-full appearance-none focus:outline-[0] focus:ring-1 focus:ring-inset focus:ring-primary-5 transition-all ease-in-out duration-150',
          {
            rounded: !side,
            'rounded-l': side === 'left',
            'rounded-r': side === 'right'
          },
          { 'leading-4 h-7 px-2 text-xs': size === 'xs' },
          { 'leading-4 py-[5px] px-2 text-sm': size === 'small' },
          { 'leading-4 py-[7px] px-3 text-md sm:text-sm sm:leading-6': size === 'medium' },
          { 'pr-6': withClose && (size === 'small' || size === 'xs') },
          { 'pl-8': Icon && (size === 'small' || size === 'xs') },
          {
            'bg-neutral-3 hover:bg-neutral-4 focus:bg-neutral-3 border border-neutral-3 dark:bg-gray-100 dark:border-gray-100 text-gray-750 placeholder:text-gray-600':
              color === 'neutral'
          },
          {
            'border border-neutral-3 hover:border-neutral-4 text-gray-750 placeholder:text-gray-500':
              color === 'default'
          },
          {
            'bg-neutral-400 opacity-50': Boolean(disabled)
          },
          { truncate },
          inputClassName
        )}
      />
      {Icon ? (
        <Icon
          className={classNames(
            'absolute z-10 top-1/2 -translate-y-1/2 w-4 h-4 left-2 mx-px text-gray-500',
            {
              // 'w-4 h-4 left-2 mx-px text-gray-500': size === 'md' || size === 'medium'
              // 'w-4 h-4 left-2 mx-px text-gray-500': size === 'sm' || size === 'small',
              // 'w-4 h-4 left-2 mx-px text-gray-500': size === 'xs' || size === 'xsmall'
            }
          )}
        />
      ) : null}

      {withClose && rest.value ? (
        <Button
          onPress={() => {
            rest.onChange?.({
              target: {
                ...localRef.current,
                value: ''
              }
            });
            localRef.current?.focus();
          }}
          className={classNames('absolute top-1/2 right-1.5 -translate-y-1/2', {
            'right-2': size === 'small'
          })}
        >
          <XMarkIcon
            className={classNames('text-gray-500 hover:text-gray-900 ', {
              'w-4 h-4': size === 'md' || size === 'medium',
              'w-4 h-4': size === 'sm' || size === 'small',
              'w-4 h-4': size === 'xs' || size === 'xsmall'
            })}
          />
        </Button>
      ) : null}

      {unit ? (
        <span
          className={classNames('absolute top-1/2 -translate-y-1/2 text-gray-700 font-mono', {
            'right-2 text-xs': size === 'small' || size === 'xs'
          })}
        >
          {unit}
        </span>
      ) : null}
    </div>
  );
});
